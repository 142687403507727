import { mainTheme } from "shared/ui/styles";

export const styles = {
  image: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    objectFit: "cover",
    transition: "0.2s",
  },

  list: {
    margin: "0px",
    width: "384px",
    maxHeight: "356px",

    [mainTheme.breakpoints.down("md")]: {
      margin: "0px",
      width: "stretch",
      height: "stretch",
      overflow: "hidden",
      maxHeight: "min-content",
    },
  },

  listItem: {
    cursor: "pointer",
    overflow: "hidden",
    transition: "0.2s",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "dominant.white",

    "&:hover": {
      opacity: "0.9",
      borderColor: "primary.main",
    },
  },

  name: {
    left: "6.17%",
    height: "22px",
    bottom: "9.17%",
    display: "flex",
    padding: "4px 6px",
    borderRadius: "6px",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "dominant.white",
  },
};
