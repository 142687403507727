import { mainTheme } from "shared/ui/styles";

export const styles = {
  firstStep: {
    skeleton: {
      width: "120px",
      height: "80px",

      [mainTheme.breakpoints.down("md")]: {
        width: "100%",
        height: "100px",
      },
    },

    wrapper: {
      gap: "12px",
      display: "grid",
      gridTemplateRows: "repeat(3, 1fr)",
      gridTemplateColumns: "repeat(3, 1fr)",

      [mainTheme.breakpoints.down("md")]: {
        gridTemplateRows: "unset",
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },
  },

  middleSteps: {
    hr: {
      margin: "16px 0 0 0",
    },

    image: {
      width: "100%",
      height: "220px",
    },

    buttons: {
      display: "flex",
      marginTop: "16px",
      alignItems: "center",
      justifyContent: "space-between",
    },

    skeleton: {
      width: "120px",
      height: "42px",

      [mainTheme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    wrapper: {
      gap: "12px",
      display: "grid",
      marginTop: "16px",
      gridTemplateRows: "repeat(2, 1fr)",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
};
