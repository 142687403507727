"use client";

import { FC, useEffect } from "react";

import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  useMediaQuery,
  MobileStepper,
} from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import {
  MIDDLE_STEPS,
  TITLE_OPTIONS,
  MOBILE_STEP_IMAGES,
} from "../../lib/constants";
import { FinalStep } from "../FinalStep";
import { FirstStep } from "../FirstStep";
import { MiddleSteps } from "../MiddleSteps";
import { QuestionnaireSkeleton } from "../QuestionnaireSkeleton";
import { SuccessModal } from "../SuccessModal";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { getQuestionnaireDataQuery } from "@/shared/model/api/questionnaire";
import {
  $step,
  resetStep,
  QuestionnaireGate,
  resetQuestionnaire,
  returnToPreviousStep,
  openQuestionnaireModal,
  closeQuestionnaireModal,
  $isQuestionnaireModalOpen,
} from "@/shared/model/store/questionnaire";
import {
  TQuestionnaireSteps,
  TQuestionnaireArrivalCountry,
} from "@/shared/model/types/questionnaire";
import { DialogWrapper } from "@/shared/ui";
import {
  TCloseReason,
  CLOSE_ON_BACKDROP,
} from "@/shared/ui/DialogWrapper/DialogWrapper";

export const DEFAULT_BUTTON_PROPS = {
  color: "primary",
  variant: "contained",
  disableElevation: true,
  endIcon: <ArrowForward />,
} as ButtonProps;

export const QuestionnaireButton: FC<ButtonProps> = (props) => {
  const t = useTranslations();
  const [sendDataLayerEventFn, resetStepFn, openQuestionnaireModalFn] = useUnit(
    [sendDataLayerEvent, resetStep, openQuestionnaireModal],
  );

  const handleOpen = () => {
    sendDataLayerEventFn({
      event: "findTourClick",
    });
    resetStepFn();
    openQuestionnaireModalFn();
  };

  return (
    <Button {...props} onClick={handleOpen}>
      {props.children ?? t("FIND_A_TOUR")}
    </Button>
  );
};

export const QuestionnaireModal: FC = () => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const t = useTranslations();

  useGate(QuestionnaireGate);

  const [step, isModalOpen] = useUnit([$step, $isQuestionnaireModalOpen]);

  const [closeModal, resetForm, returnToPreviousStepFn] = useUnit([
    closeQuestionnaireModal,
    resetQuestionnaire,
    returnToPreviousStep,
  ]);

  const otherSteps = MIDDLE_STEPS.includes(step);

  const { data: answersList, pending: isLoading } = useUnit(
    getQuestionnaireDataQuery,
  );

  const handleClose = (_?: any, reason?: TCloseReason) => {
    if (reason === CLOSE_ON_BACKDROP) {
      return;
    }
    closeModal();
  };

  const goToPrevStep = (step: TQuestionnaireSteps) => {
    step === 1 ? handleClose() : returnToPreviousStepFn();
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <>
      <DialogWrapper
        step={`${step}/5`}
        open={isModalOpen}
        onClose={handleClose}
        sx={styles.questionsModal}
        title={t(TITLE_OPTIONS[step])}
        fullScreen={!isDesktop && step === 1}
        onBackButtonClick={() => goToPrevStep(step)}
        WrapperProps={otherSteps ? styles.dialogWrapperSx : {}}
        background={!isDesktop ? MOBILE_STEP_IMAGES[step] : ""}
        BackdropProps={{
          sx: styles.backdropSx,
        }}
      >
        <MobileStepper
          steps={6}
          position="static"
          activeStep={step}
          variant="progress"
          sx={styles.stepper}
          backButton={undefined}
          nextButton={undefined}
          LinearProgressProps={{
            sx: styles.linearProgress,
          }}
        />
        {isLoading || !answersList ? (
          <QuestionnaireSkeleton step={step} isDesktop={isDesktop} />
        ) : (
          <>
            <Condition match={step === 1}>
              <FirstStep
                answersList={answersList as TQuestionnaireArrivalCountry[]}
              />
            </Condition>

            <Condition match={otherSteps}>
              <MiddleSteps isDesktop={isDesktop} answersList={answersList} />
            </Condition>

            <Condition match={step === 5}>
              <FinalStep isDesktop={isDesktop} />
            </Condition>
          </>
        )}
      </DialogWrapper>
      <SuccessModal />
    </>
  );
};
