import { TQuestionnaireChip } from "../../types/questionnaire";

export const handleSelectionChanged = (
  selectedChip: TQuestionnaireChip,
  defaultValues: TQuestionnaireChip[],
) => {
  const isValueFound = defaultValues.some(
    (value) => selectedChip.id === value.id,
  );

  if (isValueFound) {
    return defaultValues.filter((value) => value.id !== selectedChip.id);
  }

  return [...defaultValues, selectedChip];
};
