import { mainTheme } from "@/shared/ui/styles";

export const styles = {
  title: {
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "36px",
  },

  subtitle: {
    fontSize: "18px",
    maxWidth: "600px",
    lineHeight: "28px",
  },

  content: {
    rowGap: "24px",
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
  },

  container: {
    display: "flex",
    marginTop: "80px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  button: {
    maxWidth: "216px",
    color: "primary.main",
    backgroundColor: "dominant.white",

    "&:hover": {
      backgroundColor: "content.grey-100",
    },

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },

  image: {
    width: "304px",
    height: "282px",
    overflow: "hidden",
    borderRadius: "16px",
    position: "relative",

    [mainTheme.breakpoints.down("md")]: {
      width: "80%",
      height: "248px",
      margin: "0 auto",
      boxShadow: "0 4px 15px -2px rgba(74, 85, 104, 0.7)",
    },
  },

  wrapper: {
    width: "100%",
    height: "378px",
    display: "flex",
    maxWidth: "1094px",
    marginLeft: "-60px",
    alignItems: "center",
    backgroundSize: "cover",
    color: "dominant.white",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    background: "url(/img/questionnaire/bg.png)",

    [mainTheme.breakpoints.down("md")]: {
      marginLeft: "0",
      height: "unset",
      background: "none",
      marginTop: "-200px",
      padding: "230px 0 40px",
      backgroundColor: "primary.main",
    },
  },
};
