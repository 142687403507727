import { TQuestionnaireChip } from "@/shared/model/types/questionnaire";

export const isPrimaryChip = (
  selectedValues: null | TQuestionnaireChip | TQuestionnaireChip[],
  id: string,
) => {
  if (Array.isArray(selectedValues)) {
    return selectedValues.some((item) => item.id === id);
  }
  return selectedValues?.id === id;
};
