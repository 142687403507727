import { FC } from "react";

import { Box, ImageList, Typography, ImageListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import {
  goToNextStep,
  setQuestionnaireCountry,
} from "@/shared/model/store/questionnaire";
import {
  TQuestionnaireCountry,
  TQuestionnaireArrivalCountry,
} from "@/shared/model/types/questionnaire";
import { Image } from "@/shared/ui";

type TProps = {
  answersList?: TQuestionnaireArrivalCountry[];
};

export const FirstStep: FC<TProps> = ({ answersList = [] }) => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [setCountry, goToNextStepFn] = useUnit([
    setQuestionnaireCountry,
    goToNextStep,
  ]);

  const handleClick = (country: TQuestionnaireCountry) => {
    setCountry(country);
    goToNextStepFn();
  };

  return (
    <ImageList
      gap={12}
      sx={styles.list}
      cols={isDesktop ? 3 : 2}
      rowHeight={isDesktop ? 80 : 100}
    >
      <Condition match={!!answersList.length}>
        {answersList.map(({ id, name, cover }) => (
          <ImageListItem
            key={id}
            sx={styles.listItem}
            onClick={() => handleClick({ id, name })}
          >
            <Image
              alt={name}
              src={cover}
              quality={100}
              imageWrapperSx={styles.image}
            />
            <Box sx={styles.name}>
              <Typography fontSize={12} lineHeight={1}>
                {name}
              </Typography>
            </Box>
          </ImageListItem>
        ))}
      </Condition>
    </ImageList>
  );
};
