import { FC } from "react";

import { Box, Button, Divider, Skeleton } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { TSteps } from "@/shared/model/types/questionnaire";

type TProps = {
  step: TSteps;
  isDesktop: boolean;
};

export const QuestionnaireSkeleton: FC<TProps> = ({
  step,
  isDesktop = false,
}) => {
  const t = useTranslations();

  switch (step) {
    case 1:
      return (
        <Box sx={styles.firstStep.wrapper}>
          {Array.from({ length: 9 }, (_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              sx={styles.firstStep.skeleton}
            />
          ))}
        </Box>
      );
    case 2:
    case 3:
    case 4:
      return (
        <Box>
          <Condition match={isDesktop}>
            <Skeleton variant="rounded" sx={styles.middleSteps.image} />
          </Condition>

          <Box sx={styles.middleSteps.wrapper}>
            {Array.from({ length: 6 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                sx={styles.middleSteps.skeleton}
              />
            ))}
          </Box>

          <Condition match={!isDesktop}>
            <Divider sx={styles.middleSteps.hr} />
          </Condition>

          <Box sx={styles.middleSteps.buttons}>
            <Button disabled>{t("BACK_2")}</Button>
            <Button disabled>{t("NEXT")}</Button>
          </Box>
        </Box>
      );
    default:
      return <></>;
  }
};
