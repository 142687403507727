import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  collapse: {
    width: "100%",
  },

  textareaWrapper: {
    height: "auto",
  },

  tooltipMargin: {
    marginBottom: "24px",
  },

  tooltipText: {
    fontSize: 16,
    lineHeight: "18.5px",
  },

  button: {
    transition: "0.2s",

    "&:hover": {
      opacity: "0.7",
    },
  },

  fields: {
    gap: "16px",
    display: "flex",
    paddingTop: "16px",
    flexDirection: "column",
  },

  buttons: {
    gap: "15px",
    display: "flex",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
};
