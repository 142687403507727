import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  linearProgress: {
    width: "100%",
  },

  stepper: {
    padding: 0,
    width: "100%",
    margin: "0 0 12px 0",
  },

  backdropSx: {
    [mainTheme.breakpoints.down("md")]: {
      backgroundColor: "unset",
    },
  },

  dialogWrapperSx: {
    [mainTheme.breakpoints.down("md")]: {
      backgroundPosition: "bottom",
      backgroundColor: "dominant.white",
    },
  },

  questionsModal: {
    padding: "24px",
    width: "stretch",
    minWidth: "432px",
    [mainTheme.breakpoints.down("md")]: {
      padding: "0 16px 34px",
      minWidth: "fit-content",
    },
  },
};
