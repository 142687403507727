import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  button: {
    transition: "0.2s",

    "&:hover": {
      opacity: "0.7",
    },
  },

  desktopImageWrapper: {
    maxHeight: "220px",
    overflow: "hidden",
    borderRadius: "8px",
  },

  wrapper: {
    maxWidth: "384px",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },

  buttons: {
    gap: "15px",
    display: "flex",
    paddingTop: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  chip: {
    cursor: "pointer",

    "&:hover": {
      border: "1px solid",
      color: "accent.brand.primary",
      borderColor: "accent.brand.primary",
    },
  },

  chipsList: {
    gap: "12px",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "14px",

    [mainTheme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },

  hr: {
    height: "1px",
    width: "100%",
    margin: "8px 0 8px 0",
    background: "content.grey-100",

    [mainTheme.breakpoints.down("md")]: {
      marginTop: "16px",
    },
  },
};
