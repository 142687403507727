/* eslint-disable perfectionist/sort-objects */
import { sample, combine, createApi, createEvent, createStore } from "effector";
import { createGate } from "effector-react";

import {
  TQuestionnaireChip,
  TQuestionnaireSteps,
  TQuestionnaireCountry,
  TQuestionnaireFormValues,
} from "../../types/questionnaire";
import { handleSelectionChanged } from "./helpers";

import {
  postQuestionnaireAnswers,
  getQuestionnaireDataQuery,
} from "@/shared/model/api/questionnaire";

export const DATA_TYPES = {
  1: "countries",
  2: "tourist-options",
  3: "hotel-categories",
  4: "tour-types",
  5: "",
};

export const QuestionnaireGate = createGate<void>();

export const $step = createStore<TQuestionnaireSteps>(1);
export const $tourType = createStore<TQuestionnaireChip[]>([]);
export const $hotelCategory = createStore<TQuestionnaireChip[]>([]);
export const $touristOption = createStore<null | TQuestionnaireChip>(null);
export const $arrivalCountry = createStore<null | TQuestionnaireChip>(null);

export const $isSuccessModalOpen = createStore(false);
export const $isQuestionnaireModalOpen = createStore(false);

export const $isNextButtonEnabled = combine(
  $step,
  $touristOption,
  $hotelCategory,
  $tourType,
  (step, touristOption, hotelCategory, tourType) => {
    switch (step) {
      case 1:
      case 5:
        return false;
      case 2:
        return Boolean(touristOption);
      case 3:
        return Boolean(hotelCategory.length);
      case 4:
        return Boolean(tourType.length);
      default:
        return true;
    }
  },
);

export const openSuccessModal = createEvent();
export const closeSuccessModal = createEvent();

export const openQuestionnaireModal = createEvent();
export const closeQuestionnaireModal = createEvent();

export const resetQuestionnaire = createEvent();
export const setQuestionnaireCountry = createEvent<TQuestionnaireCountry>();

export const setTouristOption = createEvent<TQuestionnaireChip>();
export const setHotelCategory = createEvent<TQuestionnaireChip>();
export const setTourType = createEvent<TQuestionnaireChip>();

export const sendQuestionnaireData = createEvent<TQuestionnaireFormValues>({});

export const { setStep, goToNextStep, returnToPreviousStep, resetStep } =
  createApi($step, {
    setStep: (_, v: TQuestionnaireSteps) => v,
    goToNextStep: (step) => {
      if (step !== 5) {
        return (step + 1) as TQuestionnaireSteps;
      }

      return 2;
    },
    returnToPreviousStep: (step) => {
      if (step !== 1) {
        return (step - 1) as TQuestionnaireSteps;
      }

      return step;
    },
    resetStep: () => 1,
  });

sample({
  clock: [QuestionnaireGate.open, $step],
  source: $step,
  filter: (step) => step !== 5,
  fn: (step) => DATA_TYPES[step],
  target: getQuestionnaireDataQuery.refresh,
});

sample({
  clock: resetQuestionnaire,
  fn: () => [],
  target: [$tourType, $hotelCategory],
});

sample({
  clock: setHotelCategory,
  source: $hotelCategory,
  fn: (hotelCategory, selectedChip: TQuestionnaireChip) =>
    handleSelectionChanged(selectedChip, hotelCategory),
  target: $hotelCategory,
});

sample({
  clock: setTourType,
  source: $tourType,
  fn: (tourType, selectedChip: TQuestionnaireChip) =>
    handleSelectionChanged(selectedChip, tourType),
  target: $tourType,
});

sample({
  clock: sendQuestionnaireData,
  source: {
    hotelCategory: $hotelCategory,
    arrivalCountry: $arrivalCountry,
    tourType: $tourType,
    touristOption: $touristOption,
  },
  fn: (
    { tourType, touristOption, arrivalCountry, hotelCategory },
    formValues: TQuestionnaireFormValues,
  ) => ({
    tourist_option_id: touristOption?.id ?? "",
    arrival_country_id: arrivalCountry?.id ?? "",
    tour_type_ids: tourType.map((item) => item.id),
    hotel_category_ids: hotelCategory.map((item) => item.id),
    ...formValues,
  }),
  target: postQuestionnaireAnswers.start,
});

sample({
  clock: postQuestionnaireAnswers.finished.success,
  target: [closeQuestionnaireModal, openSuccessModal],
});

$isQuestionnaireModalOpen.on(openQuestionnaireModal, () => true);
$isQuestionnaireModalOpen.on(closeQuestionnaireModal, () => false);

$isSuccessModalOpen.on(openSuccessModal, () => true);
$isSuccessModalOpen.on(closeSuccessModal, () => false);

$arrivalCountry.on(setQuestionnaireCountry, (_, v) => v);

$touristOption.on(setTouristOption, (_, v) => v);
