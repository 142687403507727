import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  dialogTitle: {
    paddingBottom: 0,
  },

  button: {
    padding: "12px 36.5px",
  },

  hr: {
    height: "1px",
    width: "100%",
    margin: "0 0 16px",
    borderColor: "content.grey-100",
  },

  buttonWrapper: {
    width: "100%",
    display: "flex",
    paddingX: "16px",
    justifyContent: "center",
  },

  dialog: {
    padding: "0",
    width: "stretch",

    [mainTheme.breakpoints.down("md")]: {
      width: "stretch",
    },
  },

  subtitle: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: "24px",
    lineHeight: "18.56px",
    [mainTheme.breakpoints.down("sm")]: {
      marginX: "16px",
    },
  },

  title: {
    fontWeight: 500,
    fontSize: "26px",
    marginTop: "16px",
    lineHeight: "30px",
    marginBottom: "8px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: 20,
      marginTop: "12px",
      lineHeight: "20px",
    },
  },

  wrapper: {
    display: "flex",
    maxWidth: "432px",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 44.5px 24px",

    [mainTheme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      padding: "16px 0",
    },
  },
};
