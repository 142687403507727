import { attach } from "effector";

import { TFFRequest } from "../../types/common/entities/common";
import {
  TQuestionnaireData,
  TPostAnswersPayload,
} from "../../types/questionnaire";

import { requestFx } from "@/shared/config";

type TQueryResponse = TFFRequest<TQuestionnaireData>;
type TMutationResponse = TFFRequest<TQuestionnaireData>;

export const getQuestionnaireDataFx = attach<string, TQueryResponse>({
  effect: requestFx,
  mapParams: (type) => ({
    url: `survey/${type}`,
  }),
});

export const postQuestionnaireMutationFx = attach<
  TPostAnswersPayload,
  TMutationResponse
>({
  effect: requestFx,
  mapParams: (payload) => ({
    data: payload,
    url: `survey`,
    method: "post",
  }),
});
