"use client";
import { useRouter, usePathname } from "next/navigation";
import { FC } from "react";

import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import {
  closeSuccessModal,
  $isSuccessModalOpen,
} from "@/shared/model/store/questionnaire";
import { DialogWrapper, CircleStatusIcon } from "@/shared/ui";

export const SuccessModal: FC = () => {
  const router = useRouter();
  const pathname = usePathname();

  const t = useTranslations();

  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [isOpen, closeModal] = useUnit([
    $isSuccessModalOpen,
    closeSuccessModal,
  ]);

  const isMainPage = pathname === "/";

  const handleClose = () => {
    closeModal();

    if (!isMainPage) {
      router.push("/");
    }
  };

  return (
    <DialogWrapper
      title={" "}
      open={isOpen}
      sx={styles.dialog}
      onClose={handleClose}
      sxTitle={styles.dialogTitle}
    >
      <Box sx={styles.wrapper}>
        <CircleStatusIcon />
        <Typography sx={styles.title}>{t("DATA_SENT")}!</Typography>

        <Typography sx={styles.subtitle}>
          {t(
            "WE_HAVE_RECEIVED_YOUR_APPLICATION_OUR_MANAGER_WILL_CONTACT_YOU_SOON",
          )}
        </Typography>

        <Condition match={isPhone}>
          <Divider sx={styles.hr} />
        </Condition>
        <Box sx={styles.buttonWrapper}>
          <Button
            sx={styles.button}
            fullWidth={isPhone}
            onClick={handleClose}
            variant={isMainPage ? "outlined" : "contained"}
          >
            {t(isMainPage ? "CLOSE" : "TO_HOME")}
          </Button>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
