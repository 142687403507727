/* eslint-disable perfectionist/sort-objects */

import { sample, combine, EventCallable } from "effector";
import { createForm } from "effector-forms";

import {
  PHONE_REGEX,
  REQUIRED_FIELD_ERROR,
  CORRECT_PHONE_ERROR_TEXT,
} from "@/shared/lib/constants";
import { checkValidationRule } from "@/shared/lib/helpers";
import { postQuestionnaireAnswers } from "@/shared/model/api/questionnaire";
import { $userLeadFormData } from "@/shared/model/store/auth";
import {
  $defaultDeparture,
  $locationsSelectable,
} from "@/shared/model/store/default-departure";
import {
  $step,
  $tourType,
  $hotelCategory,
  $touristOption,
  $arrivalCountry,
} from "@/shared/model/store/questionnaire";
import { TPostAnswersPayload } from "@/shared/model/types/questionnaire";

type TQuestionnaireForm = {
  name: string;
  city: string;
  phone: string;
  message?: string;
};

export const $locationOptions = $locationsSelectable.map(
  (locations) =>
    locations?.map(({ id, name }) => ({
      id: id,
      value: id,
      label: name,
    })) ?? [],
);

export const $otherStepsValues = combine(
  $tourType,
  $hotelCategory,
  $touristOption,
  $arrivalCountry,
  (tourType, hotelCategory, touristOption, arrivalCountry) => ({
    tourist_option_id: touristOption?.id ?? "",
    arrival_country_id: arrivalCountry?.id ?? "",
    tour_type_ids: tourType.map((item) => item.id),
    hotel_category_ids: hotelCategory.map((item) => item.id),
  }),
);

export const $valuesForAnalytics = combine(
  $tourType,
  $hotelCategory,
  $touristOption,
  $arrivalCountry,
  (tourType, hotelCategory, touristOption, arrivalCountry) => ({
    country: arrivalCountry?.name,
    peopleCount: touristOption?.name,
    vacationType: tourType.map((item) => item.name),
    hotelRating: hotelCategory.map((item) => item.name),
  }),
);

export const $$questionnaireForm = createForm<TQuestionnaireForm>({
  fields: {
    name: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) => Boolean(value),
          errorText: REQUIRED_FIELD_ERROR,
        },
      ],
    },
    phone: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) =>
            Boolean(value) && checkValidationRule(value, PHONE_REGEX),
          errorText: CORRECT_PHONE_ERROR_TEXT,
        },
      ],
    },
    city: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) => Boolean(value),
        },
      ],
    },
    message: {
      init: "",
    },
  },
  validateOn: ["submit"],
});

sample({
  clock: $step,
  source: { userData: $userLeadFormData, defaultDeparture: $defaultDeparture },
  filter: (_, step) => step === 5,
  fn: ({ userData, defaultDeparture }) =>
    ({
      name: userData?.name,
      phone: userData?.phone,
      city: defaultDeparture?.id ?? "",
    }) as TQuestionnaireForm,
  target: $$questionnaireForm.setForm as EventCallable<TQuestionnaireForm>,
});

sample({
  clock: $$questionnaireForm.formValidated,
  source: $otherStepsValues,
  fn: (otherValues, { name, phone, message, city }) =>
    ({
      ...otherValues,
      name,
      phone,
      message,
      departure_location_id: city,
    }) as TPostAnswersPayload,
  target: postQuestionnaireAnswers.start,
});

sample({
  clock: postQuestionnaireAnswers.finished.success,
  target: $$questionnaireForm.reset as EventCallable<void>,
});
