import { createQuery, createMutation } from "@farfetched/core";

import {
  getQuestionnaireDataFx,
  postQuestionnaireMutationFx,
} from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getQuestionnaireDataQuery = createQuery({
  name: "getQuestionnaireData",
  effect: getQuestionnaireDataFx,
  mapData: ({ result }) => result.data.data,
});

export const postQuestionnaireAnswers = createMutation({
  name: "postQuestionnaireAnswers",
  effect: postQuestionnaireMutationFx,
});

QueryTools(getQuestionnaireDataQuery).errors.handleAll();
