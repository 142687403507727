import { FC, SyntheticEvent } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useForm } from "effector-forms";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import {
  $locationOptions,
  $$questionnaireForm,
  $valuesForAnalytics,
} from "../../model";
import { styles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { postQuestionnaireAnswers } from "@/shared/model/api/questionnaire";
import { setStep } from "@/shared/model/store/questionnaire";
import {
  Input,
  Select,
  NewInput,
  LoaderButton,
  NewInputMask,
} from "@/shared/ui";

type TProps = {
  isDesktop?: boolean;
};

export const FinalStep: FC<TProps> = ({ isDesktop = false }) => {
  const t = useTranslations();
  const [sendDataLayerEventFn, setStepFn] = useUnit([
    sendDataLayerEvent,
    setStep,
  ]);

  const { fields, submit, isDirty, errorText } = useForm($$questionnaireForm);
  const { pending } = useUnit(postQuestionnaireAnswers);

  const [options, combinedValues] = useUnit([
    $locationOptions,
    $valuesForAnalytics,
  ]);

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    try {
      sendDataLayerEventFn({ data: combinedValues, event: "fillLeadForm" });
    } catch (error) {
      console.log(error);
    }

    submit();
  };

  return (
    <>
      <Box sx={styles.tooltipText}>
        <Typography sx={styles.tooltipMargin}>
          {t(
            "FILL_OUT_THE_FORM_AND_WE_WILL_CALL_YOU_BACK_ONCE_WE_HAVE_PREPARED_PERSONALIZED_TOURS",
          )}
        </Typography>
        <Typography>
          {t(
            "IMMEDIATELY_AFTER_SENDING_YOU_WILL_HAVE_ACCESS_TO_THE_RESULTS_OF_OUR_SELECTOR",
          )}
        </Typography>
      </Box>

      <form onSubmit={onSubmit}>
        <Box sx={styles.fields}>
          <NewInput
            label={t("NAME")}
            value={fields.name.value}
            placeholder={t("YOUR_NAME")}
            errorMessage={t(errorText("name"))}
            onChange={(e) => fields.name.onChange(e.target.value)}
          />
          <NewInputMask
            label={t("PHONE")}
            mask={"+7 999 999-99-99"}
            value={fields.phone.value}
            placeholder="+7 (___) ___-____"
            errorMessage={t(errorText("phone"))}
            onChange={(e) => fields.phone.onChange(e.target.value)}
          />
          <Select
            label={t("CITY")}
            options={options}
            value={fields.city.value}
            placeholder={t("SELECT_CITY")}
            // TODO: select types
            // @ts-expect-error
            onChange={(v) => fields.city.onChange(v)}
          />
          <Input
            multiline
            minRows={5}
            value={fields.message?.value}
            placeholder={t("I_WANT_TO_DIVE")}
            sxWrapper={styles.textareaWrapper}
            label={t("ADDITIONAL_WISHES_OPTIONAL")}
            onChange={(v) => fields.message?.onChange(v)}
          />

          <Box sx={styles.buttons}>
            <Button
              sx={styles.button}
              fullWidth={!isDesktop}
              onClick={() => setStepFn(4)}
              variant={isDesktop ? "text" : "outlined"}
            >
              {t("BACK_2")}
            </Button>

            <LoaderButton
              type="submit"
              loading={pending}
              variant="contained"
              fullWidth={!isDesktop}
              disabled={!isDirty || !fields.city.isValid}
            >
              {t("SEND")}
            </LoaderButton>
          </Box>
        </Box>
      </form>
    </>
  );
};
