import Image from "next/image";
import { FC } from "react";

import { Box, Button } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { DESKTOP_STEP_IMAGES } from "../../lib/constants";
import { styles } from "./styles";

import { isPrimaryChip } from "@/entities/questionnaire/lib/helpers";

import { Condition } from "@/shared/lib/condition";
import {
  $step,
  $tourType,
  setTourType,
  goToNextStep,
  $hotelCategory,
  $touristOption,
  setHotelCategory,
  setTouristOption,
  returnToPreviousStep,
  $isNextButtonEnabled,
} from "@/shared/model/store/questionnaire";
import {
  TQuestionnaireData,
  TQuestionnaireChip,
  TQuestionnaireSteps,
} from "@/shared/model/types/questionnaire";
import { CustomChip } from "@/shared/ui";

type TProps = {
  isDesktop: boolean;
  answersList: TQuestionnaireData;
};

export const MiddleSteps: FC<TProps> = ({ isDesktop, answersList = [] }) => {
  const t = useTranslations();

  const [
    tourType,
    hotelCategory,
    touristOption,
    step,
    isButtonEnabled,
    goToNextStepFn,
    returnToPreviousStepFn,
  ] = useUnit([
    $tourType,
    $hotelCategory,
    $touristOption,
    $step,
    $isNextButtonEnabled,
    goToNextStep,
    returnToPreviousStep,
  ]);

  const [selectTourType, selectHotelCategory, selectTouristOption] = useUnit([
    setTourType,
    setHotelCategory,
    setTouristOption,
  ]);

  const questionnaireValues = [
    null,
    touristOption,
    hotelCategory,
    tourType,
    null,
  ];

  const handleSelect = (
    step: TQuestionnaireSteps,
    selectedChip: TQuestionnaireChip,
  ) => {
    switch (step) {
      case 1:
      case 5:
        return null;
      case 2:
        selectTouristOption(selectedChip);
        return;
      case 3:
        selectHotelCategory(selectedChip);
        return;
      default:
        selectTourType(selectedChip);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Condition match={isDesktop}>
        <Box sx={styles.desktopImageWrapper}>
          <Image
            width={384}
            height={220}
            alt={`${step}`}
            src={DESKTOP_STEP_IMAGES[step]}
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
          />
        </Box>
      </Condition>

      <Condition match={!!answersList.length}>
        <Box sx={styles.chipsList}>
          {answersList.map(({ id = "", name = "" }) => (
            <CustomChip
              key={id}
              label={name}
              sx={styles.chip}
              variant={"outlined"}
              size={isDesktop ? "medium" : "small"}
              onClick={() => handleSelect(step, { id, name })}
              color={
                isPrimaryChip(questionnaireValues[step - 1], id)
                  ? "primary"
                  : "default"
              }
            />
          ))}
        </Box>
      </Condition>

      <Condition match={!isDesktop}>
        <Box sx={styles.hr} />
      </Condition>

      <Condition match>
        <Box sx={styles.buttons}>
          <Button
            sx={styles.button}
            fullWidth={!isDesktop}
            onClick={() => returnToPreviousStepFn()}
            variant={isDesktop ? "text" : "outlined"}
          >
            {t("BACK_2")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth={!isDesktop}
            disabled={!isButtonEnabled}
            onClick={() => goToNextStepFn()}
          >
            {t("NEXT")}
          </Button>
        </Box>
      </Condition>
    </Box>
  );
};
