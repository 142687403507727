/* eslint-disable perfectionist/sort-objects */
export const TITLE_OPTIONS = {
  1: "SELECT_COUNTRY",
  2: "WHO_WILL_GO",
  3: "HOTEL_CLASS",
  4: "TYPE_OF_REST",
  5: "YOUR_DATA",
};

export const MOBILE_STEP_IMAGES = {
  1: "",
  2: "/img/questionnaire/secondStepMobile.png",
  3: "/img/questionnaire/thirdStepMobile.png",
  4: "/img/questionnaire/fourthStepMobile.png",
  5: "",
};

export const DESKTOP_STEP_IMAGES = {
  1: "",
  2: "/img/questionnaire/secondStep.jpg",
  3: "/img/questionnaire/thirdStep.png",
  4: "/img/questionnaire/fourthStep.png",
  5: "",
};

export const MIDDLE_STEPS = [2, 3, 4];
export const ALL_STEPS_EXCLUDING_FINAL = [1, ...MIDDLE_STEPS];
