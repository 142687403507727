"use client";
import Image from "next/image";
import { FC } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslations } from "next-intl";

import { QuestionnaireButton } from "../QuestionnaireModal";
import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks";
import { Container } from "@/shared/ui";

import questionnaire from "@/public/img/questionnaire/main.jpeg";

const CommonImage: FC = () => (
  <Box sx={styles.image}>
    <Image
      fill
      alt="Вопросник"
      src={questionnaire}
      style={{ objectFit: "cover" }}
    />
  </Box>
);

const Content: FC = () => {
  const t = useTranslations();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <Typography component="h2" sx={styles.title}>
          {t("DIDNT_FIND_A_SUITABLE_OPTION")}
        </Typography>
        <Typography sx={styles.subtitle}>
          {t("ANSWER_A_FEW_QUESTIONS_WE_WILL_FIND_THE_BEST_ONE")}
        </Typography>
        <QuestionnaireButton sx={styles.button} variant="contained" />
      </Box>
    </Box>
  );
};

export const QuestionnaireContainer: FC = () => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  if (isPhone) {
    return (
      <Box>
        <CommonImage />
        <Content />
      </Box>
    );
  }

  return (
    <Container sx={styles.container}>
      <CommonImage />
      <Content />
    </Container>
  );
};
